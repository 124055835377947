<div class="list">
  @for (checkbox of list; track checkbox) {
    @if (checkbox.id !== 67 && checkbox.id !== 68) {
      <mat-checkbox
        color="primary"
        class="list__checkbox checkbox--24px"
        [(ngModel)]="checkbox.selected"
        (ngModelChange)="updateDataForParent()">
        {{ 'features.' + checkbox.value | translate: locale.language }}
      </mat-checkbox>
    }
    @if (checkbox.id === 67) {
      <mat-checkbox
        color="primary"
        class="list__checkbox checkbox--24px"
        [(ngModel)]="this.searchDomain.filterAptCredit"
        (ngModelChange)="updateDataForParent()">
        {{ 'features.' + checkbox.value | translate: locale.language }}
      </mat-checkbox>
    }
    @if (checkbox.id === 68) {
      <mat-checkbox
        color="primary"
        class="list__checkbox checkbox--24px"
        [(ngModel)]="this.searchDomain.filterAptProfessionalUse"
        (ngModelChange)="updateDataForParent()">
        {{ 'features.' + checkbox.value | translate: locale.language }}
      </mat-checkbox>
    }
  }
</div>
