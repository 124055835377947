export class Tag {
  color!: string;
  fontweight?: string = Tag.FONT_WEIGHT_REGULAR;
  isButtonRemoveVisible?: boolean = false;
  public static readonly COLOR_PRIMARY: string = 'primary';
  public static readonly COLOR_PRIMARY_TONAL: string = 'primary-tonal';
  public static readonly COLOR_PRIMARY_BORDER: string = 'primary-border';
  public static readonly COLOR_SECONDARY: string = 'secondary';
  public static readonly COLOR_GREEN: string = 'green';
  public static readonly FONT_WEIGHT_REGULAR: string = 'regular';
  public static readonly FONT_WEIGHT_BOLD: string = 'bold';
}
