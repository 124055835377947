import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'qrNumber',
  pure: true,
  standalone: true,
})
export class QrNumberPipe implements PipeTransform {
  transform(value: number | string): string {
    if (value != null && value != undefined) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return '';
  }
}
