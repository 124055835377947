import { Component } from '@angular/core';

@Component({
  selector: 'app-not-save-search',
  templateUrl: './not-save-search.component.html',
  styleUrls: ['./not-save-search.component.scss'],
  standalone: true,
  imports: [],
})
export class NotSaveSearchComponent {}
