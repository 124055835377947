import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-save',
  templateUrl: './delete-save.component.html',
  styleUrls: ['./delete-save.component.scss'],
  standalone: true,
  imports: [],
})
export class DeleteSaveSearchComponent {}
