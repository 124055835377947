import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { ParamsDomain } from '../../domain/params.domain';
import { SearchDomain } from '../../domain/search.domain';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QrIconButtonComponent } from '../qr-icon-button/qr-icon-button.component';
import { ItemParam } from '../../core/models/ItemParam';
import { CheckboxGroup } from '../../core/models/checkbox-group.model';
import { Constants } from '../../core/constants/constants';

@Component({
  selector: 'qr-checkbox-group',
  templateUrl: './qr-checkbox-group.component.html',
  styleUrls: ['./qr-checkbox-group.component.scss'],
  standalone: true,
  imports: [
    L10nTranslatePipe,
    MatCheckboxModule,
    FormsModule,
    CommonModule,
    QrIconButtonComponent,
  ],
})
export class QrCheckboxGroupComponent implements OnChanges {
  allComplete = false;
  isSubcheckboxsOpen = false;

  selection: number[] = [];
  listingTypes$: Promise<ItemParam[]> | undefined;
  friendlyListingTypes: ItemParam[] | undefined;
  casaIds: number[] = [];
  departamentosIds: number[] = [];
  INNER_CASA_ID = 100;
  INNER_DEPARTAMENTO_ID = 101;

  @Input() checkboxGroup!: CheckboxGroup;
  @Input() selectionMode = 'normal'; // Para definir como funciona el codigo al seleccionar un subcheckbox
  @Output()
  dataForParent: EventEmitter<CheckboxGroup> =
    new EventEmitter<CheckboxGroup>();

  // Botón: Expandir
  configButtonExpand = {
    style: Constants.BUTTON_COLOR_GREY_BASIC,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public paramsDomain: ParamsDomain,
    public searchDomain: SearchDomain
  ) {}

  /*
	  ngOnChanges: Detecta que hubo un cambio en el componente
	*/
  ngOnChanges(): void {
    this.allComplete = this.checkboxGroup.selected;
  }

  updateAllComplete() {
    this.allComplete =
      this.checkboxGroup.subcheckboxs != null &&
      this.checkboxGroup.subcheckboxs.every(t => t.selected);

    this.checkboxGroup.selected =
      this.checkboxGroup.subcheckboxs != null &&
      this.checkboxGroup.subcheckboxs.every(t => t.selected);
    this.updateDataForParent();
  }

  someComplete(): boolean {
    if (this.checkboxGroup.subcheckboxs == null) {
      // Si los subcheckboxs son null
      return false;
    }
    return (
      this.checkboxGroup.subcheckboxs.filter(t => t.selected).length > 0 &&
      !this.allComplete // Si la cantidad de seleccionados es mayor a 0 y allComplete es false
    );
  }

  setAll(selected: boolean) {
    this.selection = this.searchDomain.filterListingType;

    this.allComplete = selected;
    this.checkboxGroup.selected = selected;
    if (this.checkboxGroup.subcheckboxs == null) {
      return;
    }
    this.checkboxGroup.subcheckboxs.forEach(t => (t.selected = selected));
    this.updateDataForParent();

    this.checkboxGroup.subcheckboxs.forEach(item => {
      const index = this.selection.findIndex(element => element == item.id);

      if (!selected) {
        if (index >= 0) {
          this.selection.splice(index, 1);
        }
      } else {
        this.selection.push(item.id);
      }
    });

    this.searchDomain.filterListingType = [...new Set(this.selection)];
  }

  toggleSubcheckboxs(): void {
    this.isSubcheckboxsOpen = !this.isSubcheckboxsOpen;
  }

  /*
	  updateDataForParent: Envia al componente padre que checkboxs estan seleccionados
	*/
  updateDataForParent(): void {
    this.dataForParent.emit(this.checkboxGroup);
  }

  /*
	onSelection2: 
	*/
  onSelection2(id: any) {
    if (this.selectionMode == 'normal') {
      // Listado de resultados
    } else if (this.selectionMode == 'property-home-mobile') {
      // Home (Mobile): Diálogo de tipo de propiedad
      this.selection = this.searchDomain.filterListingType;
      const index = this.selection.findIndex(element => element == id);

      if (index >= 0) {
        this.selection.splice(index, 1);
      } else {
        this.selection.push(id);
      }
      this.searchDomain.filterListingType = [...new Set(this.selection)];
    }
  }
}
