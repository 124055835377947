<div class="toggle">
  @for (option of config.options; track option; let i = $index) {
    <button
      class="toggle__button {{ config.hierarchy }} toggle__button-{{
        RANDOM_NUMBER
      }}"
      type="button"
      matRipple
      (click)="select(i)"
      [class.toggle__button--selected]="i === optionSelected"
      [class.toggle__button--first]="i === 0"
      [class.toggle__button--middle]="
        i !== 0 && i !== config.options.length - 1
      "
      [class.toggle__button--last]="i === config.options.length - 1">
      @if (option.icon && option.icon.name && option.icon.color) {
        <qr-icon [config]="option.icon"></qr-icon>
      }
      @if (option.value) {
        {{ option.value | translate: locale.language }}
      }
    </button>
  }
</div>
