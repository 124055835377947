import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icon } from '@base/src/app/core/models/icon.model';
import { Tag } from '@base/src/app/core/models/tag.model';
import { QrIconComponent } from '../../qr-icon/qr-icon.component';

@Component({
  selector: 'qr-tag-x',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  standalone: true,
  imports: [QrIconComponent],
})
export class TagComponent implements OnInit {
  @Input() config!: Tag;
  @Input() id?: string;
  @Output() tagChange: EventEmitter<string> = new EventEmitter<string>();
  iconRemove: Icon = {
    name: Icon.NAME_CLOSE,
    color: Icon.COLOR_PRIMARY,
  };

  ngOnInit(): void {
    switch (this.config.color) {
      case Tag.COLOR_PRIMARY:
        this.iconRemove = { ...this.iconRemove, color: Icon.COLOR_WHITE };
        break;
      case Tag.COLOR_PRIMARY_TONAL:
      case Tag.COLOR_PRIMARY_BORDER:
        this.iconRemove = { ...this.iconRemove, color: Icon.COLOR_PRIMARY };
        break;
      case Tag.COLOR_SECONDARY:
        this.iconRemove = { ...this.iconRemove, color: Icon.COLOR_SECONDARY };
        break;
      case Tag.COLOR_GREEN:
        this.iconRemove = { ...this.iconRemove, color: Icon.COLOR_GREEN };
        break;
    }
  }

  remove(): void {
    this.tagChange.emit(this.id);
  }
}
