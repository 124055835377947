@if (isLoading) {
  <div id="loader">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
}

@if (!isLoading) {
  <div id="content">
    @if (!this.isEc) {
      @for (checkboxGroup of checkboxGroups; track checkboxGroup) {
        <qr-checkbox-group
          [checkboxGroup]="checkboxGroup"
          (dataForParent)="checkboxGroupChanged($event)"></qr-checkbox-group>
      }
    }
    <div id="checkboxs">
      @if (this.isEc) {
        @for (c of checkboxGroups; track c) {
          <mat-checkbox
            [(ngModel)]="c.selected"
            [checked]="c.selected"
            (change)="checkboxChanged(c)"
            color="primary">
            {{
              'property-type.' + c.value
                | translate: locale.language
                | titlecase
            }}
          </mat-checkbox>
        }
      }
      @for (c of checkboxs; track c) {
        <mat-checkbox
          [(ngModel)]="c.selected"
          [checked]="c.selected"
          (change)="checkboxChanged(c)"
          color="primary">
          {{
            'property-type.' + c.value | translate: locale.language | titlecase
          }}
        </mat-checkbox>
      }
    </div>
  </div>
}
