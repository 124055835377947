import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GeoSearchService } from '../geo-search/geo-search.service';
import { IQrResponse } from '../../core/models/IQrResponse';
import { IGeoResult } from '../geo-search/igeo-result';
import { GeoSearch } from '../geo-search/geo-search.model';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounce } from 'rxjs/operators';
import { Observable, Subscription, interval } from 'rxjs';
import { L10N_LOCALE, L10nLocale, L10nTranslationModule } from 'angular-l10n';
import { Tag } from '../../core/models/tag.model';
import { TagComponent } from '../ui/tag/tag.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { LocationLabelPipe } from '../../pipes/location-label.pipe';

@Component({
  selector: 'locations-searcher',
  templateUrl: './locations-searcher.component.html',
  styleUrls: ['./locations-searcher.component.scss'],
  standalone: true,
  imports: [
    TagComponent,
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    //	BrowserAnimationsModule,
    MatAutocompleteModule,
    FormsModule,
    HttpClientModule,
    L10nTranslationModule,
    LocationLabelPipe,
  ],
})
export class LocationsSearcherComponent implements OnInit, OnDestroy {
  public inputFormControl: FormControl = new FormControl();
  @ViewChild('input', { static: false }) inputElement: ElementRef | undefined;
  @Input() isHomeSearcher?: boolean = false;
  @Output() locationsSearcherChange: EventEmitter<GeoSearch[]> =
    new EventEmitter<GeoSearch[]>();

  locationsSelected: GeoSearch[] = [];
  resultsAutocomplete: GeoSearch[] = [];
  // Listado de resultados del autocomplete
  inputSubscription: Subscription | undefined;
  geoSearchServiceSubscription: Subscription | undefined;
  LOCATION_TAG: Tag = {
    color: Tag.COLOR_PRIMARY_BORDER,
    fontweight: Tag.FONT_WEIGHT_BOLD,
    isButtonRemoveVisible: true,
  };

  LOCATIONS_AMOUNT_TAG: Tag = {
    color: Tag.COLOR_PRIMARY_BORDER,
    fontweight: Tag.FONT_WEIGHT_BOLD,
  };

  isLocationsMenuVisible: boolean = false;
  private selectedSubscription: Subscription | undefined;
  // Define si el buscador se esta usando en la home y se aplica un cambio en los bordes
  @Input() set selected(locationsSelected: Observable<GeoSearch[]>) {
    if (this.selectedSubscription == undefined) {
      this.selectedSubscription = locationsSelected?.subscribe(
        (geoSearch: GeoSearch[]) => {
          this.locationsSelected = [];
          if (geoSearch != null) {
            this.locationsSelected = [...geoSearch];
          }
        }
      );
    }
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private geoSearchService: GeoSearchService
  ) {}

  ngOnInit(): void {
    this.inputSubscription = this.inputFormControl.valueChanges
      .pipe(debounce(() => interval(300)))
      .subscribe((value: string) => {
        if (value?.indexOf('<b>') == -1) {
          // Con el .indexOf evitar buscar el label que auto-asigna al input por default el autocomplete.
          this.searchLocation(value);
        }
      });
  }

  ngOnDestroy(): void {
    this.inputSubscription?.unsubscribe();
    this.geoSearchServiceSubscription?.unsubscribe();
    this.selectedSubscription?.unsubscribe();
    this.selectedSubscription = undefined;
  }

  searchLocation(value: string): void {
    if (value) {
      value = value.trim();
      this.geoSearchServiceSubscription = this.geoSearchService
        .geoSearch(value, 1)
        .subscribe((RESPONSE: IQrResponse<IGeoResult>) => {
          this.resultsAutocomplete = RESPONSE.data.geoSearch;
        });
    }
  }

  removeHTMLLabel(): string {
    return '';
  }

  add(e: GeoSearch): void {
    const GEO_SEARCH_SELECTED: GeoSearch | undefined =
      this.resultsAutocomplete.find((result: GeoSearch) => result.id === e.id);
    if (GEO_SEARCH_SELECTED) {
      const GEO_SEARCH_PARSED = new GeoSearch();
      GEO_SEARCH_PARSED.setMapEvent(GEO_SEARCH_SELECTED);
      GEO_SEARCH_PARSED.id = GEO_SEARCH_SELECTED.id;
      GEO_SEARCH_PARSED.citie = GEO_SEARCH_SELECTED.citie;
      if (GEO_SEARCH_PARSED.citie) {
        GEO_SEARCH_PARSED.citie = this.toTitleCase(GEO_SEARCH_PARSED.citie);
      }
      GEO_SEARCH_PARSED.countie = GEO_SEARCH_SELECTED.countie;
      if (GEO_SEARCH_PARSED.countie) {
        GEO_SEARCH_PARSED.countie = this.toTitleCase(GEO_SEARCH_PARSED.countie);
      }
      GEO_SEARCH_PARSED.level = GEO_SEARCH_SELECTED.level;
      GEO_SEARCH_PARSED.neighborhood = GEO_SEARCH_SELECTED.neighborhood;
      GEO_SEARCH_PARSED.privatecommunitie =
        GEO_SEARCH_SELECTED.privatecommunitie;
      if (GEO_SEARCH_PARSED.privatecommunitie) {
        GEO_SEARCH_PARSED.privatecommunitie = this.toTitleCase(
          GEO_SEARCH_PARSED.privatecommunitie
        );
      }
      GEO_SEARCH_PARSED.rootCount = GEO_SEARCH_SELECTED.rootCount;
      GEO_SEARCH_PARSED.rootLabel = GEO_SEARCH_SELECTED.rootLabel;
      GEO_SEARCH_PARSED.slug = GEO_SEARCH_SELECTED.slug;
      GEO_SEARCH_PARSED.state = GEO_SEARCH_SELECTED.state;
      GEO_SEARCH_PARSED.subregion = GEO_SEARCH_SELECTED.subregion;

      this.locationsSelected.push(GEO_SEARCH_PARSED);

      // const IS_ALREADY_INCLUDED: boolean = this.locationsSelected.some(
      //   (location: GeoSearch) => location.citieId == GEO_SEARCH_PARSED.citieId
      // );
      // if (!IS_ALREADY_INCLUDED) {
      //   this.locationsSelected.push(GEO_SEARCH_PARSED);
      // }
    }
    this.resultsAutocomplete = [];

    this.closeLocationsMenu();
    this.focus();
    this.locationsSearcherChange.emit(this.locationsSelected);
  }

  remove(label: string | undefined): void {
    if (label) {
      this.locationsSelected = this.locationsSelected.filter(
        (location: GeoSearch) => location.label !== label
      );
      this.locationsSearcherChange.emit(this.locationsSelected);
      this.focus();
    }
  }

  keydownChange(event: KeyboardEvent): void {
    if (!this.isLocationsMenuVisible && event.key !== 'Enter') {
      this.openLocationsMenu();
    }
  }

  focus(): void {
    if (this.inputElement) {
      this.inputElement?.nativeElement.focus(); // Hace focus sobre el input (Te deja el input seleccionado listo para escribir)
    }
  }

  openLocationsMenu(): void {
    this.isLocationsMenuVisible = true;
    this.focus();
  }

  closeLocationsMenu(): void {
    this.isLocationsMenuVisible = false;
  }

  toTitleCase(str: string): string {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}
