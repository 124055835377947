<div id="checkbox-parent-expandable">
  <mat-checkbox
    [checked]="allComplete"
    [indeterminate]="someComplete()"
    (change)="setAll($event.checked)"
    color="primary">
    {{ 'property-type.' + checkboxGroup.value | translate: locale.language }}
  </mat-checkbox>
</div>
@if (checkboxGroup.subcheckboxs) {
  <qr-icon-button
    [ngClass]="{ open: isSubcheckboxsOpen }"
    icon="arrow-drop-down"
    [config]="this.configButtonExpand"
    (click)="toggleSubcheckboxs()">
  </qr-icon-button>
}

<div id="subcheckboxs" [ngClass]="{ open: isSubcheckboxsOpen }">
  <ul>
    @for (subcheckbox of checkboxGroup.subcheckboxs; track subcheckbox) {
      <li>
        <mat-checkbox
          (change)="onSelection2(subcheckbox.id)"
          [checked]="subcheckbox.selected"
          [(ngModel)]="subcheckbox.selected"
          (ngModelChange)="updateAllComplete()"
          color="primary">
          {{
            'property-type.' + subcheckbox.value | translate: locale.language
          }}
        </mat-checkbox>
      </li>
    }
  </ul>
</div>
