import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Icon } from '@base/src/app/core/models/icon.model';
import { Toggle } from '@base/src/app/core/models/toggle.model';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslatePipe,
  L10nTranslationModule,
} from 'angular-l10n';
import { QRIconComponent } from '../qr-icon/qr-icon.component';
import { ButtonComponent } from '../button/button.component';
import { CapitalizePipe } from '@base/src/app/pipes/capitalize.pipe';

@Component({
  selector: 'qr-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  standalone: true,
  imports: [
    QRIconComponent,
    ButtonComponent,
    L10nTranslatePipe,
    CapitalizePipe,
    L10nTranslatePipe,
    L10nTranslationModule,
  ],
})
export class ToggleComponent implements OnChanges {
  @Input() config!: Toggle;
  @Input() optionSelected!: number;
  @Output()
  optionSelectedChange: EventEmitter<number> = new EventEmitter<number>();

  RANDOM_NUMBER: number = isPlatformBrowser(this.platformId)
    ? this.getSecureRandomNumber()
    : 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnChanges(): void {
    this.setIconsColors();
  }

  select(OPTION: number): void {
    this.optionSelected = OPTION;
    this.setIconsColors();
    this.optionSelectedChange.emit(this.optionSelected);
  }

  setIconsColors(): void {
    for (let i = 0; i < this.config.options.length; i++) {
      if (i == this.optionSelected) {
        let iconColor: string = Icon.COLOR_WHITE;
        if (this.config.hierarchy == Toggle.HIERARCHY_PRIMARY_BORDER) {
          iconColor = Icon.COLOR_PRIMARY;
        }
        this.config.options[i].icon = {
          ...this.config.options[i].icon,
          color: iconColor,
        };
      } else {
        this.config.options[i].icon = {
          ...this.config.options[i].icon,
          color: Icon.COLOR_GREY_WOLF,
        };
      }
    }
  }

  getSecureRandomNumber(): number {
    const RANDOM_ARRAY: Uint32Array = new Uint32Array(1);
    window.crypto.getRandomValues(RANDOM_ARRAY);
    const SECURE_RANDOM_NUMBER: number = RANDOM_ARRAY[0] % 101;
    return SECURE_RANDOM_NUMBER;
  }
}
