import { CommonModule, TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { L10nLocale, L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';

@Component({
  selector: 'qr-input-number',
  templateUrl: './qr-input-number.component.html',
  styleUrls: ['./qr-input-number.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    L10nTranslatePipe,
    TitleCasePipe,
    CommonModule,
  ],
})
export class QrInputNumberComponent implements OnInit {
  visibleValue: string | null = null; // Valor que es visible para el usuario final con formato
  @Input() valueNumber!: string | null; // Valor real sin formato que maneja el código por detras
  @Input() label: string = '';
  @Input() hint: string = '';
  @Output() onchange = new EventEmitter<string | null>();

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit(): void {
    this.formatValue(this.valueNumber);
  }

  /*
	private unFormatValue() {
		const value = this.visibleValue;
		if (value) {
			this.valueNumber = value.replace(/[^\d.-]/g, ''); // eliminar todo carácter no númerico
			this.valueNumber = value.replace(/\./g, ''); // eliminar puntos '.'
		}

		if (value) {
			this.visibleValue = this.valueNumber;
		} else {
			this.visibleValue = '';
		}
	}
	*/
  blur() {
    this.formatValue(this.valueNumber);
  }

  /*
	focus() {
		// this.unFormatValue();
	}
	*/
  input(event: Event) {
    const target = event.target as HTMLInputElement | null;
    if (target) {
      this.valueNumber = target.value.replace(/[^\d.-]/g, ''); // eliminar todo carácter no númerico
      this.valueNumber = target.value.replace(/\./g, ''); // eliminar puntos '.'
      this.onchange.emit(this.valueNumber);
      this.formatValue(this.valueNumber);
    }
  }

  /*
	numberWithDots: Agregar al valor los puntos de miles
	*/
  numberWithDots(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  /*
	keyup: Al escribir habilitar o deshabilitar algunos caracteres 
	*/
  keyup(e: KeyboardEvent) {
    if (
      (e.key === 'a' && e.ctrlKey === true) || // Permitir: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Permitir: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Permitir: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Permitir: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Permitir+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Permitir: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Permitir: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) || // Permitir: Cmd+X (Mac)
      e.key === 'Tab' || // Permitir: Tab
      e.key === 'Backspace' || // Permitir: Backspace
      e.key === 'Delete' // Permitir: Delete
    ) {
      return; // dejar que pase, no hago nada
    }

    // Asegurarse que si no es un número y frenar el keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  /*
	paste: Interceptar la función de pegar texto en el campo, evito strings pegados
	*/
  paste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string | undefined = event.clipboardData
      ?.getData('text/plain')
      .replace(/\D/g, ''); // get a digit-only string
    document.execCommand('insertText', false, pastedInput);
  }

  /*
	formatValue: Solicita darle formato al valor ingresado
	*/
  private formatValue(value: string | null) {
    if (value !== null) {
      this.visibleValue = this.numberWithDots(value);
    } else {
      this.visibleValue = '';
    }
  }

  /*
	unFormatValue: Quita formato al valor ingresado
	*/
}
