import queryString from 'query-string';
export class Filter {
  public params: any;

  constructor(params = {}) {
    this.params = params;
  }

  toQueryString(): string {
    const target = Object.assign({}, this.params);
    const response = queryString.stringify(target, {
      skipEmptyString: true,
      skipNull: true,
    });
    return response;
  }

  toBracketQueryString(): string {
    const target = Object.assign({}, this.params);
    const response = queryString.stringify(target, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: 'bracket',
    });
    return response;
  }

  addArrayFilter(key: string, value: any): void {
    if (!this.params[key]) this.params[key] = [];
    this.params[key].push(value);
  }

  addFilter(key: string, value: any): void {
    this.params[key] = value;
  }

  removeFilter(key: string): void {
    delete this.params[key];
  }
}
