export function arraysIsEqual(arr1: any[], arr2: any[]) {
  if (arr1 == null && arr2 == null) {
    return true;
  } else if (arr1 == null || arr2 == null) {
    return false;
  } else if (arr1.length !== arr2.length) {
    return false;
  } else {
    for (let i = 0, len = arr1.length; i < len; i++) {
      if (arr1[i] != arr2[i]) {
        return false;
      }
    }
    return true;
  }
}
