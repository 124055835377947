import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';

import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { QrFilterRange } from '@app/core/models/QrFilterRange';
import { QrInputNumberComponent } from '../qr-input-number/qr-input-number.component';

@Component({
  selector: 'qr-filter-number-range',
  templateUrl: './qr-filter-number-range.component.html',
  styleUrls: ['./qr-filter-number-range.component.scss'],
  standalone: true,
  imports: [QrInputNumberComponent],
})
export class QrFilterNumberRangeComponent implements OnDestroy {
  @Input() hint: string = '';
  @Output()
  onchange: EventEmitter<QrFilterRange> = new EventEmitter<QrFilterRange>();

  _dataSelected: QrFilterRange = new QrFilterRange();
  private subscription: Subscription | undefined;

  @Input() set selected$(value: Observable<QrFilterRange>) {
    if (this.subscription === undefined) {
      this.subscription = value?.subscribe(data => {
        if (
          data != undefined &&
          data != null &&
          !Object.is(data, this._dataSelected)
        ) {
          this._dataSelected = data.clone();
        }
      });
    }
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  /*
	ngOnChanges: Detecta que hubo un cambio en el componente
	*/
  /*
	ngOnChanges(changes: SimpleChanges): void {
		//	if (this.data) {
		//		this.fromValue = this.data.from;
		//		this.toValue = this.data.to;
		//	}
	}
	*/

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  updateFrom(event: any) {
    this._dataSelected.min = Number(event) || 0;
    this.dispathEvent();
  }

  updateTo(event: any) {
    this._dataSelected.max = Number(event) || 0;
    this.dispathEvent();
  }

  /*
	  dispathEvent: Envia al componente padre el estado de los campos
	*/
  dispathEvent(): void {
    this.onchange.emit(this._dataSelected.clone());
  }
}
