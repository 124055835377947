import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';

import { ItemParam } from '@app/core/models/ItemParam';
import { Constants } from '@base/src/app/core/constants/constants';
import { ParamsDomain } from '@app/domain/params.domain';
import { SearchDomain } from '@app/domain/search.domain';
import { QrFilterPrice } from '@app/core/models/qr-filter-price';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { QrFilterRange } from '@app/core/models/QrFilterRange';
import {
  L10nLocale,
  L10N_LOCALE,
  L10nTranslatePipe,
  L10nTranslationModule,
} from 'angular-l10n';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QrFilterButtonsComponent } from '../../qr-filter-buttons/qr-filter-buttons.component';
import { QrFilterCheckboxsListComponent } from '../../qr-filter-checkboxs-list/qr-filter-checkboxs-list.component';
import { QrIconButtonComponent } from '../../qr-icon-button/qr-icon-button.component';
import { QrFilterNumberButtonsComponent } from '../../qr-filter-number-buttons/qr-filter-number-buttons.component';
import { QrFilterPriceNumberRangeComponent } from '../../qr-filter-price-number-range/qr-filter-price-number-range.component';
import { QrFilterNumberRangeComponent } from '../../qr-filter-number-range/qr-filter-number-range.component';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { QrFilterPropertyTypeComponent } from '../../qr-filter-property-type/qr-filter-property-type.component';
import { ButtonComponent } from '../../ui/button/button.component';
import { QrDropdownSelectComponent } from '../../qr-dropdown-select/qr-dropdown-select.component';
import { Button } from '@base/src/app/core/models/button.model';
import { Toggle } from '@base/src/app/core/models/toggle.model';
import { environment } from '@base/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { CountAllFiltersService } from '@base/src/app/services/count-all-with-entrpreneurship.service';
import { GeoSearch } from '../../geo-search/geo-search.model';
import { IQrResponse } from '@base/src/app/core/models/IQrResponse';
import { FormsModule } from '@angular/forms';
import { ToggleComponent } from '../../ui/toggle/toggle.component';
import { LocationsSearcherComponent } from '../../locations-searcher/locations-searcher.component';

export interface Dropdown {
  name: string;
  isOpen: boolean;
  referenceDivPosition: string;
  padding: number;
  width: number;
  height: number;
  positionX: number;
  positionY: number;
}

export interface DropdownSelect {
  placeholder: string;
  isOpen: boolean;
}

@Component({
  selector: 'app-qr-filters-list',
  templateUrl: './qr-filters-list.component.html',
  styleUrls: ['./qr-filters-list.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    TitleCasePipe,
    QrFilterButtonsComponent,
    QrFilterCheckboxsListComponent,
    QrIconButtonComponent,
    QrFilterNumberButtonsComponent,
    L10nTranslatePipe,
    MatSlideToggleModule,
    QrFilterPriceNumberRangeComponent,
    QrFilterNumberRangeComponent,
    QrFilterPropertyTypeComponent,
    CommonModule,
    ButtonComponent,
    QrDropdownSelectComponent,
    MatSlideToggleModule,
    FormsModule,
    ToggleComponent,
    LocationsSearcherComponent,
    L10nTranslationModule,
  ],
})
export class QrFiltersListComponent implements OnDestroy, OnInit {
  dataFromParent: any; // Data que le llega desde el componente padre
  @Output() dataForParent = new EventEmitter<any>();

  dataOperation$!: Promise<ItemParam[]>;
  dataCondition$: Promise<ItemParam[]>;
  dataFeatures$: Promise<ItemParam[]>;
  listingTypes$: Promise<ItemParam[]>;
  currencyTypes$: Promise<ItemParam[]>;
  yearBuild$: Promise<ItemParam[]> | undefined;
  roomsTypes$: Promise<ItemParam[]>;
  bathroomsTypes$: Promise<ItemParam[]>;
  bedroomsTypes$: Promise<ItemParam[]>;
  parkingSpacesType$: Promise<ItemParam[]>;
  dataStage$: Promise<ItemParam[]> | undefined;

  dropdownSelectPropertyType: DropdownSelect = {
    placeholder: 'list.propertyType',
    isOpen: false,
  };

  dropdownPropertyType: Dropdown = {
    name: 'filter-property-type',
    isOpen: false,
    referenceDivPosition: '',
    padding: 0,
    width: 328,
    height: 436,
    positionX: 0,
    positionY: 0,
  };

  dropdownSelectOtherCharact: DropdownSelect = {
    placeholder: 'list.otherFeatures',
    isOpen: false,
  };

  dropdownOtherCharact: Dropdown = {
    name: 'select-other-charact',
    isOpen: false,
    referenceDivPosition: '',
    padding: 0,
    width: 328,
    height: 436,
    positionX: 0,
    positionY: 0,
  };

  readonly DROPDOWN_ID_PROPERTY_TYPE: string = 'select-property-type';
  readonly DROPDOWN_ID_OTHER_CHARACT: string = 'select-other-charact';

  operationButtons: Button[] = [];
  yearBuildButtons: Button[] = [];
  stagesButtons: Button[] = [];

  isToggleEntrepreneurshipEnable: boolean = true;

  operationToggle: Toggle = {
    options: [],
    hierarchy: Toggle.HIERARCHY_PRIMARY_SOLID,
  };

  operationToggleSelected: number = 0;

  ENVIRONMENT: string = environment.node;
  NODE_ARGENTINA: string = Constants.NODE_ARGENTINA;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public paramsDomain: ParamsDomain,
    public searchDomain: SearchDomain,
    private countAllFiltersService: CountAllFiltersService
  ) {
    this.handleOperation();
    this.listingTypes$ = this.paramsDomain.getListingsTypes();
    this.dataCondition$ = this.paramsDomain.getCondicionsTypes();
    this.dataFeatures$ = this.paramsDomain.getFeaturesTypes();
    this.currencyTypes$ = this.paramsDomain.getCurrenciesTypes();
    this.handleYearBuild();
    this.roomsTypes$ = this.paramsDomain.getRoomsTypes();
    this.bathroomsTypes$ = this.paramsDomain.getBathRoomsTypes();
    this.bedroomsTypes$ = this.paramsDomain.getBedroomsTypes();
    this.parkingSpacesType$ = this.paramsDomain.getParkingSpacesTypes();
    this.handleStages();
  }

  ngOnInit(): void {
    this.handleEntrepreneurshipToggle();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleOperation(): void {
    this.dataOperation$ = this.paramsDomain.getOperationsTypes();
    this.dataOperation$
      .then((itemParams: ItemParam[]) => {
        itemParams.forEach((itemParam: ItemParam) => {
          const BUTTON: Button = {
            id: itemParam.id,
            hierarchy: Button.HIERARCHY_GREY_BORDER,
            size: Button.SIZE_48_PX,
            value: 'list.' + itemParam.value,
          };
          const TOGGLE_OPTION: { value: string } = {
            value: 'list.' + itemParam.value,
          };
          this.operationButtons.push(BUTTON);
          this.operationToggle.options.push(TOGGLE_OPTION);
        });
      })
      .finally(() => {
        if (
          this.searchDomain.filterOperation &&
          this.searchDomain.filterOperation.length > 0
        ) {
          const OPERATION_BUTTON_SELECTED: Button | undefined =
            this.operationButtons.find(
              (button: Button) =>
                button.id === this.searchDomain.filterOperation[0]
            );
          if (OPERATION_BUTTON_SELECTED) {
            this.setOperationButton(OPERATION_BUTTON_SELECTED.value);
            this.operationToggleSelected =
              this.searchDomain.filterOperation[0] - 1;
          }
        }
      });
  }

  handleYearBuild(): void {
    this.yearBuild$ = this.paramsDomain.getYearsBuildTypes();
    this.yearBuild$
      .then((itemParams: ItemParam[]) => {
        itemParams.forEach((itemParam: ItemParam) => {
          const BUTTON: Button = {
            id: itemParam.id,
            hierarchy: Button.HIERARCHY_GREY_BORDER,
            size: Button.SIZE_48_PX,
            value: 'list.' + itemParam.value,
          };
          this.yearBuildButtons.push(BUTTON);
        });
      })
      .finally(() => {
        if (
          this.searchDomain.filterYearBuild &&
          this.searchDomain.filterYearBuild.length > 0
        ) {
          const YEAR_BUILD_BUTTON_SELECTED: Button | undefined =
            this.yearBuildButtons.find(
              (button: Button) =>
                button.id === this.searchDomain.filterYearBuild[0]
            );
          if (YEAR_BUILD_BUTTON_SELECTED) {
            this.setYearBuildButton(YEAR_BUILD_BUTTON_SELECTED.value);
          }
        }
      });
  }

  handleStages(): void {
    this.dataStage$ = this.paramsDomain.getStage();
    this.dataStage$
      .then((itemParams: ItemParam[]) => {
        itemParams.forEach((itemParam: ItemParam) => {
          const BUTTON: Button = {
            id: itemParam.id,
            hierarchy: Button.HIERARCHY_GREY_BORDER,
            size: Button.SIZE_48_PX,
            value: 'entrepreneurship.' + itemParam.value,
          };
          this.stagesButtons.push(BUTTON);
        });
      })
      .finally(() => {
        if (
          this.searchDomain.filterStage &&
          this.searchDomain.filterStage.length > 0
        ) {
          this.searchDomain.filterStage.forEach((stageID: number) => {
            const STAGE_BUTTON_SELECTED: Button | undefined =
              this.stagesButtons.find(
                (button: Button) => button.id === stageID
              );
            if (STAGE_BUTTON_SELECTED) {
              this.setStageButton(STAGE_BUTTON_SELECTED.value);
            }
          });
        }
      });
  }

  locationsChanged(value: GeoSearch[]): void {
    if (value != null) {
      this.searchDomain.filterGeoList = value;
      this.countFilters();
    }
  }

  operationToggleChange(optionSelected: number): void {
    this.searchDomain.filterOperation = [optionSelected + 1];
    this.countFilters();
  }

  // filterRoomsChanged: Ambientes
  filterRoomsChanged(value: number[]): void {
    this.searchDomain.filterRooms = value;
    this.countFilters();
  }

  //  filterPriceChanged: Precio
  filterPriceChanged(value: QrFilterPrice): void {
    this.searchDomain.filterPrice = value;
    this.countFilters();
  }

  // filterExpensesChanged: Expensas
  filterExpensesChanged(value: QrFilterPrice): void {
    this.searchDomain.filterExpense = value;
    this.countFilters();
  }

  //  filterBathroomsChanged: Baños
  filterBathroomsChanged(value: number[]): void {
    this.searchDomain.filterBathrooms = value;
    this.countFilters();
  }

  // filterBedroomsChanged: Dormitorios
  filterBedroomsChanged(value: number[]): void {
    this.searchDomain.filterBedrooms = value;
    this.countFilters();
  }

  // filterIsSuitableCreditChanged: Apto crédito
  filterIsSuitableCreditChanged(value: MatSlideToggleChange): void {
    this.searchDomain.filterAptCredit = value.checked;
    this.countFilters();
  }

  //  filterOtherFeaturesChanged: Otras características
  filterOtherFeaturesChanged(value: number[]): void {
    //Feature 67 -> apto credito
    //Feature 68 -> apto profesional (deprecados en el backend)
    this.searchDomain.filterAptCredit = value.indexOf(67) != -1;
    this.searchDomain.filterAptProfessionalUse = value.indexOf(68) != -1;
    this.searchDomain.filterFeatures = value;
    this.countFilters();
  }

  //  filterTotalSurfaceChanged: Superficie total
  filterTotalSurfaceChanged(value: QrFilterRange): void {
    this.searchDomain.filterDimensionBuilt = value;
    this.countFilters();
  }

  // filterTotalSurfaceChanged: Superficie Terreno
  filterTotalSurfaceLand(value: QrFilterRange): void {
    this.searchDomain.filterDimensionLand = value;
    this.countFilters();
  }

  //  filterCoverSurfaceChanged: Superficie cubierta
  filterCoverSurfaceChanged(value: QrFilterRange): void {
    this.searchDomain.filterDimensionCovered = value;
    this.countFilters();
  }

  //  filterGarageChanged: Cochera
  filterGarageChanged(value: number[]): void {
    this.searchDomain.filterParkingSpace = value;
    this.countFilters();
  }

  //  filterPropertyTypeChanged: Tipo de propiedad
  filterPropertyTypeChanged(value: number[]): void {
    this.searchDomain.filterListingType = value;
    this.countFilters();
  }

  handlePropertyTypeValue(
    data: { id: number; value: string; order: number }[]
  ): string[] {
    const s: string[] = [];
    for (const filter of data) {
      if (this.searchDomain.filterListingType.includes(filter.id)) {
        if (
          filter.value.includes('departamento') &&
          !s.includes('property-type.departamento')
        ) {
          s.push('property-type.departamento');
        } else if (
          filter.value.includes('casa') &&
          !s.includes('property-type.casa')
        ) {
          s.push('property-type.casa');
        } else {
          s.push('property-type.' + filter.value);
        }
      }
    }
    return s;
  }

  // filterListingTypeChanged(value: number[]): void {
  //   if (value != null) {
  //     this.searchDomain.filterListingType = [...value];
  //     if (this.searchDomain.isFilterChange()) {
  //       this.searchDomain.page = 0;
  //       if (this.dataFromParent.isEntrepreneurship) {
  //         this.searchDomain.windowLocationHrefEmpre();
  //       } else {
  //         this.searchDomain.windowLocationHref();
  //       }
  //     }
  //   }
  // }

  filterListingTypeChanged(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedOptions = Array.from(selectElement.selectedOptions); // Obtener las opciones seleccionadas
    const value = selectedOptions.map(option => Number(option.value)); // Convertir a números

    if (value != null) {
      this.searchDomain.filterListingType = [...value];

      if (this.searchDomain.isFilterChange()) {
        this.searchDomain.page = 0;

        if (this.dataFromParent.isEntrepreneurship) {
          this.searchDomain.windowLocationHrefEmpre();
        } else {
          this.searchDomain.windowLocationHref();
        }
      }
    }
  }

  toggleDropdown(e: string): void {
    switch (e) {
      case this.DROPDOWN_ID_PROPERTY_TYPE:
        this.dropdownPropertyType.isOpen = !this.dropdownPropertyType.isOpen;
        break;
      case this.DROPDOWN_ID_OTHER_CHARACT:
        this.dropdownOtherCharact.isOpen = !this.dropdownOtherCharact.isOpen;
        break;
    }
  }

  getDisplayedItems(data: string[]): string[] {
    if (data.length > 3) {
      return data.slice(0, 3);
    }
    return data;
  }

  getRemainingItemsCount(data: string[]): number {
    if (data.length > 3) {
      return data.length - 3;
    }
    return 0;
  }

  getSelectedFilters(data: ItemParam[]): ItemParam[] {
    return data.filter(
      filter =>
        this.searchDomain.filterFeatures.includes(Number(filter.id)) &&
        Number(filter.id) != 67 &&
        Number(filter.id) != 68
    );
  }

  getDisplayedFilters(data: ItemParam[]): ItemParam[] {
    const selectedFilters: ItemParam[] = this.getSelectedFilters(data);
    return selectedFilters.slice(0, 2);
  }

  getRemainingFiltersCount(data: ItemParam[]): number {
    const selectedFilters: ItemParam[] = this.getSelectedFilters(data);
    if (selectedFilters.length > 2) {
      return selectedFilters.length - 2;
    }
    return 0;
  }

  toggleEntrepreneurshipChange(value: MatSlideToggleChange): void {
    if (value.checked) {
      // Emprendimientos incluidos en resultados
      this.searchDomain.filterAreEntrepreneurshipsExcluded = false; // Los emprendimientos no son excluidos
      this.stagesButtons.forEach((button: Button) => {
        button.hierarchy = Button.HIERARCHY_PRIMARY_SOLID;
      });
      this.searchDomain.filterStage = [0, 1, 2, 3, 4];
    } else {
      this.searchDomain.filterAreEntrepreneurshipsExcluded = true;
      this.cleanStage();
    }
    this.countFilters();
  }

  handleEntrepreneurshipToggle(): void {
    if (this.searchDomain.filterAreEntrepreneurshipsExcluded) {
      this.isToggleEntrepreneurshipEnable = false;
    } else {
      this.isToggleEntrepreneurshipEnable = true;
    }
  }

  changeYearBuild(buttonSelected: Button): void {
    if (
      !this.searchDomain.filterYearBuild.includes(Number(buttonSelected.id))
    ) {
      this.setYearBuildButton(buttonSelected.value);
      this.searchDomain.filterYearBuild = [Number(buttonSelected.id)];
    } else {
      this.cleanYearBuild();
    }
    this.countFilters();
  }

  cleanYearBuild(): void {
    this.setYearBuildButton();
    this.searchDomain.filterYearBuild = [];
  }

  setOperationButton(value?: string): void {
    this.operationButtons.forEach((button: Button) => {
      button.hierarchy = Button.HIERARCHY_GREY_BORDER;
    });
    const buttonSelected: Button | undefined = this.operationButtons.find(
      (item: Button) => item.value === value
    );
    if (buttonSelected) {
      buttonSelected.hierarchy = Button.HIERARCHY_PRIMARY_SOLID;
    }
  }

  changeOperation(buttonSelected: Button): void {
    if (
      !this.searchDomain.filterOperation.includes(Number(buttonSelected.id))
    ) {
      this.setOperationButton(buttonSelected.value);
      this.searchDomain.filterOperation = [Number(buttonSelected.id)];
      this.operationToggleSelected = Number(buttonSelected.id) - 1;
    }
    this.countFilters();
  }

  setYearBuildButton(value?: string): void {
    this.yearBuildButtons.forEach((button: Button) => {
      button.hierarchy = Button.HIERARCHY_GREY_BORDER;
    });
    const buttonSelected: Button | undefined = this.yearBuildButtons.find(
      (item: Button) => item.value === value
    );
    if (buttonSelected) {
      buttonSelected.hierarchy = Button.HIERARCHY_PRIMARY_SOLID;
    }
  }

  changeStage(buttonSelected: Button): void {
    if (this.isToggleEntrepreneurshipEnable) {
      if (!this.searchDomain.filterStage.includes(Number(buttonSelected.id))) {
        this.setStageButton(buttonSelected.value);
        this.searchDomain.filterStage = [
          ...this.searchDomain.filterStage,
          Number(buttonSelected.id),
        ];
      } else {
        this.cleanStage(buttonSelected);
      }
    }
    this.countFilters();
  }

  cleanStage(buttonSelected?: Button): void {
    if (buttonSelected) {
      this.searchDomain.filterStage = this.searchDomain.filterStage.filter(
        (stageID: number) => stageID !== buttonSelected.id
      );
      this.setStageButton(buttonSelected.value);
    } else {
      this.searchDomain.filterStage = [];
      this.setStageButton();
    }
  }

  setStageButton(value?: string): void {
    if (!value) {
      this.stagesButtons.forEach((button: Button) => {
        button.hierarchy = Button.HIERARCHY_GREY_BORDER;
      });
    } else {
      const BUTTON_SELECTED: Button | undefined = this.stagesButtons.find(
        (item: Button) => item.value === value
      );
      if (BUTTON_SELECTED) {
        if (BUTTON_SELECTED.hierarchy == Button.HIERARCHY_PRIMARY_SOLID) {
          BUTTON_SELECTED.hierarchy = Button.HIERARCHY_GREY_BORDER;
        } else {
          BUTTON_SELECTED.hierarchy = Button.HIERARCHY_PRIMARY_SOLID;
        }
      }
    }
  }

  private countFilters(): void {
    this.searchDomain
      .generateFilters()
      .then(() => {
        const QUERY: string = this.dataFromParent.isEntrepreneurship
          ? this.searchDomain.qrFilter.toQueryStringBe() +
            `&eq=entrepreneurship:true`
          : this.searchDomain.qrFilter.toQueryStringBe();

        this.countAllFiltersService
          .getParams<string, IQrResponse<number>>(QUERY)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response: IQrResponse<number>) => {
              this.searchDomain.filterCountResponse = response.data;
            },
            error: error => {
              console.error('Error calling count service:', error);
            },
          });
      })
      .catch(error => {
        console.error('Error generating filters:', error);
      });
  }
}
