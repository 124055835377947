import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';

import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ItemParam } from '@app/core/models/ItemParam';
import { QrButtonComponent } from '../qr-button/qr-button.component';

@Component({
  selector: 'qr-filter-number-buttons',
  templateUrl: './qr-filter-number-buttons.component.html',
  styleUrls: ['./qr-filter-number-buttons.component.scss'],
  standalone: true,
  imports: [QrButtonComponent],
})
export class QrFilterNumberButtonsComponent implements OnDestroy {
  buttons: any[] = [];

  @Input() numbersText: string[] = [];
  @Output() onchange: EventEmitter<number[]> = new EventEmitter<number[]>();
  _dataSelected: any[] = [];
  private subscription: Subscription | undefined;

  private _data: ItemParam[] = [];

  @Input()
  set options(value: ItemParam[]) {
    if (value != undefined && value != null) {
      this._data = value;
      this.init();
      this.updateSelected();
    }
  }

  @Input() set selected$(value: Observable<any[]>) {
    if (this.subscription === undefined) {
      this.subscription = value?.subscribe(data => {
        if (!Object.is(data, this._dataSelected)) {
          this._dataSelected = [...data];
          this.updateSelected();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  /*
	updateDataForParent: Envia al componente padre que números estan seleccionados
	*/
  updateDataForParent(buttonIndex: number): void {
    const lastButton = this.buttons.length - 1;
    if (buttonIndex == lastButton && this.buttons[lastButton].selected) {
      // [+5] des-selecciona el resto de botones
      for (let i = 0; i < lastButton; i++) {
        const button = this.buttons[i];
        button.selected = false;
        this.buttons[i] = Object.assign({}, button); // Actualizar objeto
      }
    }

    if (buttonIndex != lastButton) {
      // Al seleccionar cualquier otro botón se des-seleccionar el botón [+5]

      const button = this.buttons[lastButton];
      button.selected = false;
      this.buttons[lastButton] = Object.assign({}, button); // Actualizar objeto
    }

    this._dataSelected = [];
    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];
      if (button.selected) {
        this._dataSelected.push(button.id);
      }
    }
    this.onchange.emit(this._dataSelected);
  }

  private init() {
    this._data.forEach(item => {
      this.buttons.push({
        id: item.id,
        style: Constants.BUTTON_COLOR_GREY_BORDER,
        height: Constants.BUTTON_HEIGHT_48PX,
        text: item.value,
        selected: false,
        isDisabled: false,
        changeStyleOnSelected: true,
        styleOnSelected: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      });
    });
  }

  private updateSelected() {
    if (
      this._data &&
      this._data.length > 0 &&
      this._dataSelected &&
      this._dataSelected.length > 0
    ) {
      for (let x = 0; x < this.buttons.length; x++) {
        // Reinicio de todos los selected
        const button = this.buttons[x];
        button.selected = this._dataSelected.includes(button.id);
        this.buttons[x] = Object.assign({}, button); // Actualizar objeto
      }
    }
  }
  /*
	ngOnChanges: Detecta que hubo un cambio en el componente
	*/
  //ngOnChanges(): void {

  //}
}
