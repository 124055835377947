@if (isDialogOpen) {
  <div id="container" [style.height]="heightValue" [style.width]="widthValue">
    <!-- Barra superior -->
    @if (this.isTopbarVisible) {
      <div>
        <div id="topbar">
          @if (data.titleFilter) {
            <h3>
              {{ data.titleFilter }}
            </h3>
          } @else {
            <h3>
              {{ 'list.filters' | translate: locale.language | titlecase }}
            </h3>
          }
          <qr-icon-button
            (click)="close()"
            icon="close"
            [config]="this.configButtonClose"></qr-icon-button>
        </div>
        <div id="topbar-space"></div>
      </div>
    }
    <div id="content-wrapper" [ngClass]="heightClasses">
      <!-- Componente dinámico -->
      <template #dinamicComponent></template>
    </div>
    <!-- Barra inferior -->
    @if (isBotbarVisible) {
      <div id="botbar">
        @if (this.configButtonLeft) {
          <qr-button
            (click)="leftButton()"
            id="button-left"
            [config]="this.configButtonLeft">
          </qr-button>
        }
        @if (this.configButtonRight) {
          <qr-button
            (click)="rightButton()"
            id="button-right"
            [config]="this.configButtonRight"></qr-button>
        }
      </div>
    }
  </div>
}
@if (isDialogOpen) {
  <div id="shadow" (click)="close()" aria-hidden="true"></div>
}

<!-- search-save.component.html -->
<app-toast
  [message]="'Se guardó la búsqueda correctamente'"
  [isVisible]="isToastVisible"></app-toast>
