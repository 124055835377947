export function string2Number(input: any) {
  if (input == null || input == undefined) {
    return 0;
  }
  const numeric = Number(input);
  if (isNaN(numeric)) {
    return 0;
  } else {
    return numeric;
  }
}
