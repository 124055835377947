<!-------------------------------------->
<!-- Resultados: Operación -->
<!-------------------------------------->
@if (!dataFromParent.isEntrepreneurship) {
  @if (dataOperation$ | async; as data) {
    <div class="filter filter--mid filter--s-height">
      <div class="filter__title">
        <h3 class="subheading-01-bold">
          {{ 'operations.operation' | translate: locale.language | titlecase }}
        </h3>
      </div>
      <div class="filter__content">
        <qr-toggle
          class="filter__operation-toggle"
          [config]="operationToggle"
          [optionSelected]="operationToggleSelected"
          (optionSelectedChange)="operationToggleChange($event)"></qr-toggle>
        <div class="filter__buttons filter__operation-buttons">
          @for (button of operationButtons; track button) {
            <qr-button-x
              (click)="changeOperation(button)"
              class="filter__button"
              [config]="button"></qr-button-x>
          }
        </div>
      </div>
    </div>
  } @else {
    <mat-spinner diameter="24"></mat-spinner>
  }
}
<!-------------------------------------->
<!-- Emprendimientos: Etapa -->
<!-------------------------------------->
@if (ENVIRONMENT === NODE_ARGENTINA && dataFromParent.isEntrepreneurship) {
  @if (dataStage$ | async; as data) {
    <div class="filter filter--xl">
      <div class="filter__title">
        <h3 class="subheading-01-bold">
          {{
            'entrepreneurship.entrepreneurships'
              | translate: locale.language
              | titlecase
          }}
        </h3>
      </div>
      <ng-container *ngTemplateOutlet="stages"></ng-container>
    </div>
  } @else {
    <mat-spinner diameter="24"></mat-spinner>
  }
}
<!-------------------------------------->
<!-- Ubicación -->
<!-------------------------------------->
<div
  [ngClass]="
    dataFromParent.isEntrepreneurship
      ? 'filter filter--xl filter--s-height'
      : 'filter filter--mid filter--s-height'
  ">
  <div class="filter__title">
    <h3 class="subheading-01-bold">
      {{ 'officeDetail.ubication' | translate: locale.language }}
    </h3>
  </div>

  <div class="filter__content">
    <locations-searcher
      class="filter__locations-searcher"
      [selected]="searchDomain.filterGeoList$"
      (locationsSearcherChange)="locationsChanged($event)"></locations-searcher>
  </div>
</div>
<!-------------------------------------->
<!-- Tipo de propiedad -->
<!-------------------------------------->
@if (listingTypes$ | async; as data) {
  <div class="filter filter--mid filter--mid-height">
    <div class="filter__title">
      <h3 class="subheading-01-bold">
        {{ 'list.propertyType' | translate: locale.language }}
      </h3>
    </div>
    <div class="filter__content">
      <div>
        <qr-dropdown-select
          [id]="DROPDOWN_ID_PROPERTY_TYPE"
          (click)="toggleDropdown(DROPDOWN_ID_PROPERTY_TYPE)"
          [options]="data"
          [config]="dropdownSelectPropertyType"
          [linkedDropdown]="dropdownPropertyType.isOpen">
        </qr-dropdown-select>
        @if (dropdownPropertyType.isOpen) {
          <qr-filter-property-type
            [selected$]="searchDomain.filterListingType$"
            [options]="data"
            (onClose)="filterListingTypeChanged($event)"
            (onchange)="filterPropertyTypeChanged($event)">
          </qr-filter-property-type>
        }
      </div>
      <div class="filter__selected">
        @for (
          item of getDisplayedItems(handlePropertyTypeValue(data));
          track item
        ) {
          <p>
            {{ item | translate: locale.language | titlecase }}
          </p>
        }
        @if (getRemainingItemsCount(handlePropertyTypeValue(data)) > 0) {
          <p>+{{ getRemainingItemsCount(handlePropertyTypeValue(data)) }}</p>
        }
      </div>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-------------------------------------->
<!-- Precio -->
<!-------------------------------------->
@if (currencyTypes$ | async; as data) {
  <div class="filter filter--mid filter--mid-height">
    <div class="filter__title">
      <h3 class="subheading-01-bold">
        {{ 'list.price' | translate: locale.language }}
      </h3>
    </div>
    <div class="filter__content">
      <qr-filter-price-number-range
        [currencies]="data"
        [selected$]="searchDomain.filterPrice$"
        (onchange)="filterPriceChanged($event)">
      </qr-filter-price-number-range>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-------------------------------------->
<!-- Ambientes -->
<!-------------------------------------->
@if (roomsTypes$ | async; as data) {
  <div class="filter filter--mid filter--sx-height">
    <div class="filter__title">
      <h3 class="subheading-01-bold">
        {{ 'list.rooms' | translate: locale.language | titlecase }}
      </h3>
    </div>
    <div class="filter__content">
      <qr-filter-number-buttons
        [selected$]="searchDomain.filterRooms$"
        [options]="data"
        (onchange)="filterRoomsChanged($event)">
      </qr-filter-number-buttons>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-------------------------------------->
<!-- Dormitorios -->
<!-------------------------------------->
@if (bedroomsTypes$ | async; as data) {
  <div class="filter filter--mid filter--sx-height">
    <div class="filter__title">
      <h3 class="subheading-01-bold">
        {{ 'list.bedrooms' | translate: locale.language | titlecase }}
      </h3>
    </div>
    <div class="filter__content">
      <qr-filter-number-buttons
        [selected$]="searchDomain.filterBedrooms$"
        [options]="data"
        (onchange)="filterBedroomsChanged($event)">
      </qr-filter-number-buttons>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-------------------------------------->
<!-- Baños -->
<!-------------------------------------->
@if (bathroomsTypes$ | async; as data) {
  <div class="filter filter--mid filter--sx-height">
    <div class="filter__title">
      <h3 class="subheading-01-bold">
        {{ 'list.bathrooms' | translate: locale.language | titlecase }}
      </h3>
    </div>
    <div class="filter__content">
      <qr-filter-number-buttons
        [selected$]="searchDomain.filterBathrooms$"
        [options]="data"
        (onchange)="filterBathroomsChanged($event)">
      </qr-filter-number-buttons>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-------------------------------------->
<!-- Cochera -->
<!-------------------------------------->
@if (parkingSpacesType$ | async; as data) {
  <div class="filter filter--mid filter--sx-height">
    <div class="filter__title">
      <h3 class="subheading-01-bold">
        {{ 'list.garage' | translate: locale.language | titlecase }}
      </h3>
    </div>
    <div class="filter__content">
      <qr-filter-number-buttons
        [selected$]="searchDomain.filterParkingSpace$"
        [options]="data"
        (onchange)="filterGarageChanged($event)">
      </qr-filter-number-buttons>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-------------------------------------->
<!-- Superficie total -->
<!-------------------------------------->
<div class="filter filter--mid filter--mid-height">
  <div class="filter__title">
    <h3 class="subheading-01-bold">
      {{ 'list.totalSurface' | translate: locale.language }}
    </h3>
  </div>
  <div class="filter__content">
    <qr-filter-number-range
      [selected$]="searchDomain.filterDimensionBuilt$"
      hint="m²"
      (onchange)="filterTotalSurfaceChanged($event)">
    </qr-filter-number-range>
  </div>
</div>
<!-------------------------------------->
<!-- Superficie cubierta -->
<!-------------------------------------->
<div class="filter filter--mid filter--mid-height">
  <div class="filter__title">
    <h3 class="subheading-01-bold">
      {{ 'list.coverSurface' | translate: locale.language }}
    </h3>
  </div>

  <div class="filter__content">
    <qr-filter-number-range
      [selected$]="searchDomain.filterDimensionCovered$"
      hint="m²"
      (onchange)="filterCoverSurfaceChanged($event)">
    </qr-filter-number-range>
  </div>
</div>
<!-------------------------------------->
<!-- Superficie terreno -->
<!-------------------------------------->
<div class="filter filter--mid filter--mid-height">
  <div class="filter__title">
    <h3 class="subheading-01-bold">
      {{ 'list.totalLand' | translate: locale.language }}
    </h3>
  </div>

  <div class="filter__content">
    <qr-filter-number-range
      [selected$]="searchDomain.filterDimensionLand$"
      hint="m²"
      (onchange)="filterTotalSurfaceLand($event)">
    </qr-filter-number-range>
  </div>
</div>
<!-------------------------------------->
<!-- Expensas -->
<!-------------------------------------->
@if (currencyTypes$ | async; as data) {
  <div class="filter filter--mid filter--mid-height">
    <div class="filter__title">
      <h3 class="subheading-01-bold">
        {{ 'list.expenses' | translate: locale.language }}
      </h3>
    </div>
    <div class="filter__content">
      <qr-filter-price-number-range
        [currencies]="data"
        [selected$]="searchDomain.filterExpense$"
        (onchange)="filterExpensesChanged($event)">
      </qr-filter-price-number-range>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-------------------------------------->
<!-- Antigüedad -->
<!-------------------------------------->
@if (yearBuild$ | async; as data) {
  <div class="filter filter--xl">
    <div class="filter__title">
      <h3 class="subheading-01-bold">
        {{ 'list.yearBuilt' | translate: locale.language }}
      </h3>
    </div>
    <div class="filter__content">
      <div class="filter__buttons">
        @for (button of yearBuildButtons; track button) {
          <qr-button-x
            (click)="changeYearBuild(button)"
            class="filter__button"
            [config]="button"></qr-button-x>
        }
      </div>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-------------------------------------->
<!-- Otras características -->
<!-------------------------------------->
@if (dataFeatures$ | async; as data) {
  <div class="filter filter--xl filter--mid-height">
    <div class="filter__title">
      <h3 class="subheading-01-bold">
        {{ 'list.otherFeatures' | translate: locale.language }}
      </h3>
    </div>
    <div class="filter__content">
      <div>
        <qr-dropdown-select
          class="filter__dropdown--other-features"
          [id]="DROPDOWN_ID_OTHER_CHARACT"
          (click)="toggleDropdown(DROPDOWN_ID_OTHER_CHARACT)"
          [options]="data"
          [config]="dropdownSelectOtherCharact"
          [linkedDropdown]="dropdownOtherCharact.isOpen">
        </qr-dropdown-select>
        @if (dropdownOtherCharact.isOpen) {
          <qr-filter-checkboxs-list
            [options]="data"
            [selected$]="searchDomain.filterFeatures$"
            (onchange)="filterOtherFeaturesChanged($event)">
          </qr-filter-checkboxs-list>
        }
      </div>
      <div class="filter__selected">
        @if (this.searchDomain.filterAptCredit) {
          <p>
            {{ 'features.apto_credito' | translate: locale.language }}
          </p>
        }
        @if (this.searchDomain.filterAptProfessionalUse) {
          <p>
            {{ 'features.apto_profesional' | translate: locale.language }}
          </p>
        }
        @for (filter of getDisplayedFilters(data); track filter) {
          <p>
            {{ 'features.' + filter.lang | translate: locale.language }}
          </p>
        }
        @if (getRemainingFiltersCount(data) > 0) {
          <p>+{{ getRemainingFiltersCount(data) }}</p>
        }
      </div>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-------------------------------------->
<!-- Apto crédito -->
<!-------------------------------------->
<div class="filter filter__title filter__title--switch">
  <h3 class="subheading-01-bold">
    {{ 'listing-detail.feature_apt_credit' | translate: locale.language }}
  </h3>
  <mat-slide-toggle
    class="filter__switch"
    [checked]="searchDomain.filterAptCredit"
    (change)="filterIsSuitableCreditChanged($event)"
    color="primary">
  </mat-slide-toggle>
</div>
<!-------------------------------------->
<!-- Resultados: Emprendimientos y Etapa -->
<!-------------------------------------->
@if (ENVIRONMENT === NODE_ARGENTINA && !dataFromParent.isEntrepreneurship) {
  @if (dataStage$ | async; as data) {
    <div class="filter filter--xl">
      <div class="filter__title filter__title--switch">
        <h3 class="subheading-01-bold">
          {{
            'entrepreneurship.entrepreneurships'
              | translate: locale.language
              | titlecase
          }}
        </h3>
        <mat-slide-toggle
          class="filter__switch"
          [(ngModel)]="isToggleEntrepreneurshipEnable"
          color="primary"
          (change)="toggleEntrepreneurshipChange($event)"></mat-slide-toggle>
      </div>
      <ng-container *ngTemplateOutlet="stages"></ng-container>
    </div>
  } @else {
    <mat-spinner diameter="24"></mat-spinner>
  }
}

<!-- Etapas -->
<ng-template #stages>
  <div class="filter__content">
    <h3 class="filter__subtitle">
      {{ 'entrepreneurship.stage' | translate: locale.language | titlecase }}
    </h3>
    <div class="filter__buttons">
      @for (button of stagesButtons; track button) {
        <qr-button-x
          (click)="changeStage(button)"
          class="filter__button"
          [config]="button"
          [isDisabled]="!isToggleEntrepreneurshipEnable"></qr-button-x>
      }
    </div></div
></ng-template>

<!-------------------------------------->
<!-- Mientras carga los parametros -->
<!-------------------------------------->
