import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../core/services/http.service';
import { catchError, first, retry } from 'rxjs/operators';
import { environment } from '@base/environments/environment';
import { Observable, throwError } from 'rxjs';
import { Filter } from '../core/models/filters.model';

@Injectable({ providedIn: 'root' })
export class FavoritesService extends HttpService {
  constructor(http: HttpClient) {
    super(http, 'user-favorites/');
  }

  getMyFavorites(filters: Filter): Promise<any> {
    return new Promise(resolve => {
      this.setJwtToken()
        .then(headers => {
          resolve(this.getAll(filters, headers));
        })
        .catch(error => console.log(error));
    });
  }

  addFavorite(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.setJwtToken()
        .then(headers => {
          this.http
            .post(environment.apiUrl + this._url, body, {
              headers: headers,
            })
            .pipe(first())
            .subscribe(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
        })
        .catch(error => console.log(error));
    });
  }

  deleteFavoritesByIds(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.setJwtToken()
        .then(headers => {
          this.http
            .post(environment.apiUrl + this._url + 'deleteByIds/', body, {
              headers: headers,
            })
            .pipe(first())
            .subscribe(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
        })
        .catch(error => console.log(error));
    });
  }

  deleteFavoritesByListingId(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.setJwtToken()
        .then(headers => {
          this.http
            .post(
              environment.apiUrl + this._url + 'deleteByListingIds/',
              body,
              {
                headers: headers,
              }
            )
            .pipe(first())
            .subscribe(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
        })
        .catch(error => console.log(error));
    });
  }

  deleteAccountFavorites(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.setJwtToken()
        .then(headers => {
          this.http
            .delete(environment.apiUrl + this._url + 'all/', {
              headers: headers,
            })
            .pipe(first())
            .subscribe(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
        })
        .catch(error => console.log(error));
    });
  }

  getAll(filter: Filter = new Filter(), headers: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAllObservable(filter, headers)
        .pipe(first())
        .subscribe(
          data => {
            const response = JSON.parse(JSON.stringify(data));
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  getAllObservable(
    filter: Filter = new Filter(),
    headers: any
  ): Observable<any> {
    const baseUrl = environment.apiUrl + this._url + 'my-favorites';
    const queryString =
      baseUrl && baseUrl.includes(environment.apiUrl)
        ? filter.toBracketQueryString()
        : filter.toQueryString();
    return this.http
      .get(baseUrl + `?${queryString}`, {
        headers: headers,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
