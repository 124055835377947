export class QRInput {
  // Forzar a que el input este seleccionado/focus
  public static INPUT_TYPE_BUTTON = 'button';
  public static INPUT_TYPE_CHECKBOX = 'checkbox';
  public static INPUT_TYPE_COLOR = 'color';
  public static INPUT_TYPE_DATE = 'date';
  public static INPUT_TYPE_DATETIME_LOCAL = 'datetime-local';
  public static INPUT_TYPE_EMAIL = 'email';
  public static INPUT_TYPE_FILE = 'file';
  public static INPUT_TYPE_HIDDEN = 'hidden';
  public static INPUT_TYPE_IMAGE = 'image';
  public static INPUT_TYPE_MONTH = 'month';
  label: string | undefined;

  public static INPUT_TYPE_NUMBER = 'number';
  // Label del input.
  type: string | undefined;

  public static INPUT_TYPE_PASSWORD = 'password';
  // Tipo de input ej: text, tel, email.
  name: string = '';

  attribute?: string | undefined;

  public static INPUT_TYPE_RADIO = 'radio';
  id: string | undefined;

  // Identificador del input.
  placeholder?: string | undefined;

  public static INPUT_TYPE_RANGE = 'range';
  // Valor que debe escribir el usuario
  hint?: string | undefined;

  // Texto de ayuda al usuario.
  error?: string | undefined;

  // Muestra el error.
  className?: string | undefined;

  public static INPUT_TYPE_RESET = 'reset';
  // Clase propia del input
  format?: string | undefined;

  public static INPUT_TYPE_SEARCH = 'search';
  // Definir el formato ej: número entero o decimal
  limitNumberOfCharacters?: number;

  // Limitar la cantidad de caracteres
  isFocus?: boolean = false;

  isBordersHidden?: boolean = false; // Mostrar/ocultar los bordes
  isAutocompleteSuggestionsHidden?: boolean = false;
  debounceTime?: number;

  public static INPUT_TYPE_SUBMIT = 'submit';
  public static INPUT_TYPE_TEL = 'tel';
  public static INPUT_TYPE_TEXT = 'text';
  public static INPUT_TYPE_TIME = 'time';
  public static INPUT_TYPE_URL = 'url';
  public static INPUT_TYPE_WEEK = 'week';

  public static FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS =
    'FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS'; // Números enteros con puntos de miles

  public static FORMAT_TYPE_NUMBER_INTEGER = 'FORMAT_TYPE_NUMBER_INTEGER'; // Números enteros sin puntos de miles

  public static ATTRIBUTE_CHECKED = 'checked'; // Specifies that an input field should be pre-selected when the page loads (for type="checkbox" or type="radio")
  public static ATTRIBUTE_DISABLED = 'disabled'; // Specifies that an input field should be disabled
  public static ATTRIBUTE_MAX = 'max'; // Specifies the maximum value for an input field
  public static ATTRIBUTE_MAXLENGTH = 'maxlength'; // Specifies the maximum number of character for an input field
  public static ATTRIBUTE_MIN = 'min'; // Specifies the minimum value for an input field
  public static ATTRIBUTE_PATTERN = 'pattern'; // Specifies a regular expression to check the input value against
  public static ATTRIBUTE_READONLY = 'readonly'; // Specifies that an input field is read only (cannot be changed)
  public static ATTRIBUTE_REQUIRED = 'required'; // Specifies that an input field is required (must be filled out)
  public static ATTRIBUTE_SIZE = 'size'; // Specifies the width (in characters) of an input field
  public static ATTRIBUTE_STEP = 'step'; // Specifies the legal number intervals for an input field
  public static ATTRIBUTE_VALUE = 'value'; // Specifies the default value for an input field

  public static PATTERN_NUMBER: '[0-9]{3}-[0-9]{2}-[0-9]{3}';

  public static CLASSNAME_STATUS_OK = 'status-ok';
  public static CLASSNAME_STATUS_ERROR = 'status-error';
  public static CLASSNAME_STATUS_DISABLE = 'status-disable';
}
