import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { QRDropdown } from '@app/core/models/qr-dropdown.model';
import { QRIcon } from '@app/core/models/qr-icon.model';
import { QRIconComponent } from '../qr-icon/qr-icon.component';
import { L10N_LOCALE, L10nLocale, L10nTranslationModule } from 'angular-l10n';
import { ArrayToStringPipe } from '@base/src/app/pipes/array-to-string.pipe';

@Component({
  selector: 'qr-dropdown',
  templateUrl: './qr-dropdown.component.html',
  styleUrls: ['./qr-dropdown.component.scss'],
  standalone: true,
  imports: [
    MatOptionModule,
    MatRippleModule,
    FormsModule,
    MatSelectModule,
    QRIconComponent,
    L10nTranslationModule,
    ArrayToStringPipe,
  ],
})
export class QRDropdownComponent {
  @Input() config?: QRDropdown;
  @Input() selected: string | string[] | undefined;
  @Input() hasError: boolean = false;
  @ViewChild('matSelect') matSelect!: MatSelect;
  @Input() isDisabled?: boolean = false;

  @Output() onChange: EventEmitter<string | string[] | undefined> =
    new EventEmitter<string | string[] | undefined>();

  public readonly ICON_ARROW: QRIcon = {
    name: QRIcon.NAME_ARROW_DROP_DOWN,
    color: QRIcon.COLOR_BLACK,
  };

  public readonly FIX_POSITION_TOP_LIST: number = 24;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  public selectChange(): void {
    this.onChange.emit(this.selected);
  }

  public open(): void {
    if (!this.isDisabled) {
      this.matSelect.trigger.nativeElement.click();
    }
  }
}
