<!-- NO USAR ESTE COMPONENTE -->
<!-- NO USAR ESTE COMPONENTE -->
<!-- NO USAR ESTE COMPONENTE -->
<!-- Utilizar QRButtonComponent y no este que es QrButtonComponent -->
<!-- Utilizar qr-button que esta en components/ui/qr-button -->
<!-- NO USAR ESTE COMPONENTE -->
<!-- NO USAR ESTE COMPONENTE -->
<!-- NO USAR ESTE COMPONENTE -->
<button
  [class]="this.style"
  [style.height]="this.config?.height"
  [style.line-height]="this.config?.height"
  (click)="click()"
  [disabled]="this.config?.isDisabled"
  matRipple>
  {{ this.config?.text | translate: locale.language }}
</button>
