import { removeHtml } from './../../core/utils/removeHtml';
export class GeoSearch {
  public rootLabel: string = '';
  public state: string = '';
  public countie: string = '';
  public subregion: string = '';
  public citie: string = '';
  public neighborhood: string = '';
  public privatecommunitie: string = '';
  public slug: string = '';
  public id: number = 0;
  public level: number = 0;
  public rootCount: number = 0;

  rank = 0;
  private _label = '';
  private _stateId: string = '';
  private _subregionId: string = '';
  private _countieId: number = 0;
  private _citieId: number = 0;
  private _neighborhoodsId: number = 0;
  private _privateCommunityId: number = 0;

  public setMapEvent(evt: any) {
    this.label = evt.label;
    this.stateId = evt.stateId;
    this.subregionId = evt.subregionId;
    this.countieId = evt.countyId;
    this.citieId = evt.cityId;
    this.neighborhoodsId = evt.neighborhoodId;
    this.privateCommunityId = evt.privatecommunityId;
  }

  //
  get chipLabel(): string {
    let label = '';
    if (this.privatecommunitie != null && this.privatecommunitie.length > 1) {
      label = this.privatecommunitie;
    } else if (this.neighborhood != null && this.neighborhood.length > 1) {
      label = this.neighborhood;
    } else if (this.citie != null && this.citie.length > 1) {
      label = this.citie;
    } else if (this.countie != null && this.countie.length > 1) {
      label = this.countie;
    } else if (this.subregion != null && this.subregion.length > 1) {
      label = this.subregion;
    } else if (this.state != null && this.state.length > 1) {
      label = this.state;
    } else {
      // si no esta setado lo busca en el label

      const stringArray = this.label.split(',');
      label = stringArray[0].trim();
      /*
                              if(label!==null && label!== undefined){
                                 const stringArray = this.label.split(',');
                                 if(stringArray.length >1){
                                     label = stringArray[stringArray.length-1].trim();
                                  }else{
                                      label = stringArray[0].trim();
                                   }
                              }else{
                                   label="";
                              }
                              */
    }
    return removeHtml(label);
  }

  get label(): string {
    return this._label;
  }

  //
  set label(value: string) {
    if (value !== null && value !== undefined) {
      this._label = value;
    } else {
      this._label = '';
    }
  }

  get stateId(): string {
    return this._stateId;
  }

  //
  set stateId(value: string) {
    this._stateId = this.getValue(value);
  }

  get subregionId(): string {
    return this._subregionId;
  }
  //

  set subregionId(value: string) {
    this._subregionId = this.getValue(value);
  }

  get countyId(): number {
    return this._countieId;
  }

  //
  set countyId(value: number) {
    this._countieId = this.getValue(value);
  }

  get countieId(): number {
    return this._countieId;
  }

  set countieId(value: number) {
    this._countieId = this.getValue(value);
  }

  get cityId(): number {
    return this._citieId;
  }

  //
  set cityId(value: number) {
    this._citieId = this.getValue(value);
  }

  get citieId(): number {
    return this._citieId;
  }

  set citieId(value: number) {
    this._citieId = this.getValue(value);
  }

  get neighborhoodsid(): number {
    return this._neighborhoodsId;
  }

  //
  set neighborhoodsid(value: number) {
    this._neighborhoodsId = this.getValue(value);
  }

  get neighborhoodsId(): number {
    return this._neighborhoodsId;
  }

  set neighborhoodsId(value: number) {
    this._neighborhoodsId = this.getValue(value);
  }

  get privatecommunityId(): number {
    return this._privateCommunityId;
  }

  //
  set privatecommunityId(value: number) {
    this._privateCommunityId = this.getValue(value);
  }

  get privateCommunityId(): number {
    return this._privateCommunityId;
  }

  set privateCommunityId(value: number) {
    this._privateCommunityId = this.getValue(value);
  }

  clone(): GeoSearch {
    const geoSearch = new GeoSearch();
    geoSearch.label = this.label;
    geoSearch.stateId = this.stateId;
    geoSearch.subregionId = this.subregionId;
    geoSearch.countieId = this.countieId;
    geoSearch.citieId = this.citieId;
    geoSearch.neighborhoodsId = this.neighborhoodsId;
    geoSearch.privateCommunityId = this.privateCommunityId;
    return geoSearch;
  }

  isEqual(geoSearch: GeoSearch): boolean {
    if (geoSearch == null) {
      return false;
    } else {
      return (
        geoSearch.stateId == this.stateId &&
        geoSearch.subregionId == this.subregionId &&
        geoSearch.countieId == this.countieId &&
        geoSearch.citieId == this.citieId &&
        geoSearch.neighborhoodsId == this.neighborhoodsId &&
        geoSearch.privateCommunityId == this.privateCommunityId &&
        geoSearch.label == this.label
      );
    }
  }

  static encodeToFilter(list: GeoSearch[]): string {
    const stateIds: string[] = [];
    const countieIds: string[] = [];
    const subregionIds: string[] = [];
    const citieIds: string[] = [];
    const neighborhoodsIds: string[] = [];
    const privateCommunityIds: string[] = [];
    if (list != null) {
      const len = list.length;

      list.forEach(g => {
        let label = len == 1 ? g.label : g.chipLabel;
        //label = label.replace(/,/g, '-'); // cambiamos las comas por -, por que en el dedoce split(',') agrega datos del label que no deberia;
        label = label.replace(/,/g, '#'); // v2: cambiamos las comas por # por que hay nombre que contienen -, por que en el dedoce split(',') agrega datos del label que no deberia;
        if (g.privatecommunityId != null && g.privatecommunityId > 0) {
          privateCommunityIds.push(g.privatecommunityId + '@' + label);
        } else if (g.neighborhoodsId != null && g.neighborhoodsId > 0) {
          neighborhoodsIds.push(g.neighborhoodsId + '@' + label);
        } else if (g.citieId != null && g.citieId > 0) {
          citieIds.push(g.citieId + '@' + label);
        } else if (g.countieId != null && g.countieId > 0) {
          countieIds.push(g.countieId + '@' + label);
        } else if (g.subregionId != null && g.subregionId.length > 0) {
          subregionIds.push(g.subregionId + '@' + label);
        } else if (g.stateId != null && g.stateId.length > 0) {
          stateIds.push(g.stateId + '@' + label);
        }
      });
    }

    const values: string =
      stateIds.toString() +
      ':' +
      subregionIds.toString() +
      ':' +
      countieIds.toString() +
      ':' +
      citieIds.toString() +
      ':' +
      neighborhoodsIds.toString() +
      ':' +
      privateCommunityIds.toString() +
      ':';
    return values;
  }

  //
  static decodeToFromValue(value: string): any {
    const d = value.split('@');
    let label = '';

    if (d[1] !== null && d[1] !== '' ? d[1] : '') {
      //label = d[1].replace(/-/g, ','); // volemos a agregar las comas, ver encodeToFilter
      label = d[1].replace(/#/g, ','); // volemos a agregar las comas, ver encodeToFilter
    }

    return {
      value: d[0],
      label: label,
    };
  }

  ///
  static decodeToFilter(value: string): GeoSearch[] {
    const geolist: GeoSearch[] = [];
    const values: string[] = value.split(':');
    //const labels: string[] = values[7].split(',');

    if (values[1] != null && values[1] != '') {
      const stateIds: string[] = values[1].split(',');
      stateIds.forEach(s => {
        const geoSearch = new GeoSearch();
        const data = GeoSearch.decodeToFromValue(s);
        geoSearch.stateId = data.value;
        geoSearch.label = data.label;
        geolist.push(geoSearch);
      });
    }
    if (values[2] != null && values[2] != '') {
      const subregionIds: string[] = values[2].split(',');
      subregionIds.forEach(s => {
        const geoSearch = new GeoSearch();
        const data = GeoSearch.decodeToFromValue(s);
        geoSearch.subregionId = data.value;
        geoSearch.label = data.label;
        geolist.push(geoSearch);
      });
    }
    if (values[3] != null && values[3] != '') {
      const countieIds: string[] = values[3].split(',');
      countieIds.forEach(s => {
        const geoSearch = new GeoSearch();
        const data = GeoSearch.decodeToFromValue(s);
        geoSearch.countieId = +data.value;
        geoSearch.label = data.label;
        geolist.push(geoSearch);
      });
    }
    if (values[4] != null && values[4] != '') {
      const citieIds: string[] = values[4].split(',');
      citieIds.forEach(s => {
        const geoSearch = new GeoSearch();
        const data = GeoSearch.decodeToFromValue(s);
        geoSearch.citieId = +data.value;
        geoSearch.label = data.label;
        geolist.push(geoSearch);
      });
    }
    if (values[5] != null && values[5] != '') {
      const neighborhoodsIds: string[] = values[5].split(',');
      neighborhoodsIds.forEach(s => {
        const geoSearch = new GeoSearch();
        const data = GeoSearch.decodeToFromValue(s);
        geoSearch.neighborhoodsId = +data.value;
        geoSearch.label = data.label;

        geolist.push(geoSearch);
      });
    }
    if (values[6] != null && values[6] != '') {
      const privateCommunityIds: string[] = values[6].split(',');
      privateCommunityIds.forEach(s => {
        const geoSearch = new GeoSearch();
        const data = GeoSearch.decodeToFromValue(s);
        geoSearch.privateCommunityId = +data.value;
        geoSearch.label = data.label;

        geolist.push(geoSearch);
      });
    }

    return geolist;
  }

  private getValue(value: any) {
    if (value !== null && value !== undefined) {
      return value;
    } else {
      return null;
    }
  }
}
